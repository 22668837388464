/* If you need to add @import statements, do so up here */
@import "jit-refresh.css"; /* triggers frontend rebuilds */
@import 'code.css';

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --body-color: #1e293b;
  --heading-color: #1e293b;
  --action-color: #d64045;
}

body {
  color: var(--body-color);
  font-family: Inter;
  font-size: 120%;
  line-height: 1.7;
  margin-bottom:  30px;
  overflow-x:  hidden;
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
}

h1 {
  @apply text-5xl font-black pb-3;
  letter-spacing: -0.05em; 
}

h2 {
  @apply text-3xl font-light pb-3;
  letter-spacing: -0.05em; 
}

h3 {
  @apply text-3xl font-bold pb-3;
  letter-spacing: -0.05em; 
}

p {
  @apply pb-5
}

a {
  @apply text-blue-500 hover:text-blue-600 underline hover:no-underline;
}

ul {
  @apply list-disc
}

li {
  @apply pb-8
}

video {
  @apply pb-6
}

button { @apply bg-blue-100 hover:bg-blue-200 text-blue-500 hover:text-blue-600 py-1 px-2 rounded shadow border border-blue-300 }


section { @apply pb-10 max-w-3xl }

.avatar {
  max-width:  15%;
  border-radius:  3px;
  transform: rotate(1.5deg);
  border-color:  white;
  padding:  5px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)
}

.emojica {
  @apply p-12;
  width:  100%;
  margin: 0;
  background-color: #f2f7f9;
  color: #039be5;
}

#writing section { @apply pr-0 max-w-0 }
#writing h3 { @apply text-center }
#writing p {
  @apply max-w-4xl mx-auto
}

#writing ul { @apply max-w-2xl mx-auto }

.nba-card {
  @apply border p-2 bg-gray-100
}

.preview-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 20;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  body {
    background: var(--body-background);
    color: var(--body-color);
    font-size: 115%;
    line-height: 1.5;
  }

  h1 {
    @apply text-4xl font-black pb-3;
    letter-spacing: -0.05em; 
  }

  h2 {
    @apply text-2xl font-light pb-3;
    letter-spacing: -0.05em; 
  }

  h3 {
    @apply text-2xl font-bold pb-3;
    letter-spacing: -0.05em; 
  }  

  .avatar { max-width: 45%; }
  .pre { margin-left: 0; margin-right: 0; }
}

.content-gradient {
  background-image: linear-gradient(to bottom, transparent, white);
}

/*@media (prefers-color-scheme: dark) {
  body { background: #2f2b3a; color: #8b8b8b; }
  h1, h2, h3 { @apply text-yellow-500 }
  a { @apply text-yellow-500 hover:text-yellow-600 }
  code { color: #EAB308; background-color: #272822; padding: 2px; }
  button { @apply bg-black hover:bg-yellow-500 text-yellow-400 hover:text-yellow-800 py-1 px-2 rounded shadow border border-yellow-400 }
  .emojica {
    @apply p-12 text-yellow-500 bg-black border-b border-yellow-500;
    width:  100%;
    margin: 0;
  }
  .nba-card { @apply border p-2 bg-black border-yellow-500 }
  input { color: black }
}*/

